import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ChartBase from './ChartBase';
export const _frontmatter = {
  "title": "이태원 참사",
  "slug": "data17",
  "date": "2022-11-08T15:00:00.000Z",
  "thumb": "../images/thumbs/thumb17.png",
  "featuredImg": "../images/featured/featured17.png",
  "summary": "이태원 참사와 관련한 국민생각을 들어보았다.",
  "tags": ["이태원참사", "한국사회", "대형참사", "갈등"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`경향신문과 공공의창 공동기획으로 이태원 참사와 관련한 국민생각을 들어보았다.`}</p>
    <p>{`이번 조사에서 ‘이태원 참사를 책임져야 할 주체를 누구라고 보는가’라는 질문에 67.7%가 정부의 책임이라고 답변했다. 특히 33.3%가 ‘국정을 총괄하는 대통령실’을 꼽아 이번 참사와 관련해 대통령실이 포괄적인 책임을 져야 한다고 인식하는 것으로 나타났다. 이어 24.7%는 경찰청과 행정안전부, 9.7%는 용산구청에 책임이 있다고 답했다. 다만, 28.4%는 ‘시민들의 질서의식 부재’가 문제였다고 응답했다. `}</p>

    <ChartBase type={'pie'} data={props.data} columns={props.columns} mdxType="ChartBase"></ChartBase>
    <p>{`이번 참사에 ‘정부 책임이 어느 정도 되느냐’는 질문에 68.7%가 ‘정부 책임이 매우 있거나(48.5%) 어느 정도 있다(20.2%)’고 답했다. 정부 책임이 ‘전혀 없다’는 답은 7.5%, ‘어느 정도 없다’는 답은 21%에 불과했다.`}</p>
    <h2>{`참사에 대한 정부 책임`}</h2>
    <p>{`이번 참사에 ‘정부 책임이 어느 정도 되느냐’는 질문에 68.7%가 ‘정부 책임이 매우 있거나(48.5%) 어느 정도 있다(20.2%)’고 답했다. 정부 책임이 ‘전혀 없다’는 답은 7.5%, ‘어느 정도 없다’는 답은 21%에 불과했다.`}</p>
    <h2>{`이상민 행정안전부 장관 사퇴`}</h2>
    <p>{`‘이번 사안에 대해 책임을 지고 이상민 행정안전부 장관이 사퇴하는 것에 어떤 입장인가’에 절반 이상(56.8%)이 ‘사퇴에 공감한다’고 답했다. ‘사퇴에 공감하지 않는다’는 32.5%, ‘잘 모르겠다’는 10.7%였다.`}</p>
    <h2>{`‘사회적 참사’라는 인식`}</h2>
    <p>{`응답자 중 57.8%는 이번 재난이 국가적 지원을 해야 하는 사회적 참사라는 의견에 대해 ‘공감하는 편’이라고 답했다. ‘공감하지 않는 편’이라는 답은 28.7%, ‘잘 모르겠다’는 13.5%였다. 이태원 참사 후 정부는 분향소와 공식 문서 등에서 ‘참사’ 대신 ‘사고’, ‘피해자·희생자’ 대신 ‘사망자’라는 용어를 사용해 ‘책임을 회피하려 한다’는 비판을 받았다. 이와 관련해 김대기 대통령실 비서실장은 8일 “중대본에서 실무자들이 그렇게 썼는데 저희는 지금 그 용어가 그렇게 크게 중요하지 않다”며 “저희도 참사, 희생자라는 말을 썼다”고 했다.`}</p>
    <h2>{`이태원 참사 진상 규명 방식`}</h2>
    <p>{`참사의 정확한 원인 규명을 위해서는 국정조사나 특검을 해야 한다는 의견이 절반에 가까웠다. ‘여야 합의하에 국정조사’가 필요하다는 의견이 25.3%로 가장 많았고, ‘여야 합의하에 특검’(23.2%)이 뒤를 이었다. 검경 합동수사단 구성(15.9%), 대검의 감찰과 수사(15.6%), 경찰청 특별수사본부 수사(8.6%)를 통해 진상을 조사해야 한다는 의견도 있었다.
국정조사와 특검(특별검사제도)을 도입해야 한다는 응답이 많은 것은 현재 진행 중인 경찰 수사가 ‘꼬리자르기’식으로 끝날 것이라는 우려를 반영하는 것으로 보인다. 대통령실, 국무총리, 재난·안전 주무부처 행정안전부 장관 등 윗선이 아닌 일선 경찰을 처벌하는 선에서 수사를 마무리짓는 것 아니냐는 비판이다. ‘특검 도입’ 의견은 경찰의 ‘셀프 수사’가 철저한 진상규명에 장애가 될 것이라는 불신 때문으로 보인다.`}</p>
    <h2>{`한국사회 대형 참사 반복 이유`}</h2>
    <p>{`왜 이런 참사가 반복적으로 발생할까. 2014년 세월호 참사 이후에도 우리나라에서는 많은 이들이 예상치 못한 곳에서 죽음을 맞았다. 응답자들은 우리나라에서 대형 참사가 계속 발생하는 첫 번째 원인으로는 ‘안전을 경시하는 사회 분위기’(39.4%)를 꼽았다. ‘정부의 무책임’(37.5%)때문이라는 응답도 높았다. 그 외 ‘안전인력 부족(9.8%)’, ‘책임자 처벌 미흡(9.7%)’가 있었다.`}</p>
    <h2>{`세월호 참사 이후 안전 제도 정비`}</h2>
    <p>{`69.1%의 응답자들은 세월호 참사 이후에도 사회적으로 안전에 대한 법·제도 정비나 투자 등이 제대로 이루어지지 않았거나 오히려 더 나빠졌다고 느끼고 있었다. ‘안전에 관한 법·제도 정비와 안전에 대한 사회적 투자에 대해 어떻게 보느냐’는 물음에 45.1%는 ‘그때와 비슷하다’, 24%는 ‘그때보다 나빠졌다’고 답했다. 상황이 ‘나아졌다’는 답은 20.7%에 그쳤고, ‘잘 모르겠다’는 10.2%였다.`}</p>
    <h6>{`*이번 설문조사는 구조화된 설문지를 이용한 ARS 여론조사로 진행했으며 응답률은 1.6%, 표본오차는 95% 신뢰수준에 오차범위 ±3.1%포인트다. 표본 추출은 RDD 무작위 추출 방식이며 통계보정은 2022년 10월 말 행정안전부 주민등록인구 기준 성·연령·지역별 가중치 적용 방식으로 이뤄졌다.`}</h6>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      